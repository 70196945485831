<template>
  <div>
    <a-form
      id="components-form-demo-normal-login"
      :form="form"
      @submit="handleSubmit"
    >
      <a-form-item>
        <a-input
          v-decorator="['phone_number', {
            normalize: this.$lodash.trim,
            rules: [
              { required: true, message: '请输入手机号' },
              { pattern: /^1[3-9]\d{9}$/, message: '手机号格式不正确' },
            ],
            validateTrigger: 'blur'
          }]"
          placeholder="请输入手机号"
        />
      </a-form-item>

      <a-form-item>
        <a-row :gutter="8">
          <a-col :span="17">
            <a-input
              placeholder="请输入验证码"
              v-decorator="['sms_captcha', {
                normalize: this.$lodash.trim,
                rules: [{ required: true, message: '请输入验证码' }],
              }]"
            />
          </a-col>
          <a-col :span="7">
            <a-button
              type="primary"
              :block="true"
              class="register-form-captcha-button"
              @click="getSmsCaptcha"
              v-if="btnTitle"
              :disabled="disabled"
            >
              {{ btnTitle }}
            </a-button>
          </a-col>
        </a-row>
      </a-form-item>
      <a-form-item>
        <a-button
          :loading="submitting"
          type="primary"
          html-type="submit"
          class="register-form-button"
        >
          下一步
        </a-button>
      </a-form-item>

    </a-form>
  </div>
</template>

<script>
// import { verifyRegisterPhoneNumber } from '@/api/user'
import { sendSmsCaptcha, verifySmsCaptcha } from '@/api/captcha'

export default {
  name: 'RegisterFirstStep',
  data() {
    return {
      submitting: false,
      form: this.$form.createForm(this, { name: 'register_first_step' }),
      isShowAgreementModal: false,
      agreementId: 1,
      isReadAgreement: false,
      btnTitle: '获取验证码',
      disabled: false
    }
  },
  methods: {
    onChange(v) {
      this.isReadAgreement = v.target.checked
    },

    getSmsCaptcha(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }
      this.form.validateFields(['phone_number'], (err, values) => {
        if (!err) {
          if (values) {
            this.submitting = true
            sendSmsCaptcha(values).then((res) => {
              if (res.code === 0) {
                this.validateBtn()
              }
              this.submitting = false
            })
            // 去除手机号码唯一性校验
            // verifyRegisterPhoneNumber(values).then((res) => {
            //   if (res.code === 0) {
            //     this.submitting = true
            //     sendSmsCaptcha(values).then((res) => {
            //       if (res.code === 0) {
            //         this.validateBtn()
            //       }
            //       this.submitting = false
            //     })
            //   }
            // })
          }
        }
      })
    },

    validateBtn() {
      // 倒计时
      let time = 60
      const timer = setInterval(() => {
        if (time === 0) {
          clearInterval(timer)
          this.disabled = false
          this.btnTitle = '获取验证码'
        } else {
          this.btnTitle = time + '秒后重试'
          this.disabled = true
          time -= 1
        }
      }, 1000)
    },

    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }

      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          verifySmsCaptcha(values).then((res) => {
            if (res.code === 0) {
              // 告知父组件已完成
              const data = {
                next_verify_id: res.data.next_verify_id
              }
              this.$emit('completed', Object.assign(values, data))
            }
            this.form.setFieldsValue({ sms_captcha: '' })
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
  .register-form-button {
    width: 100%;
  }

  .register-form-captcha-button {
    font-size: 12px;
  }
</style>
