var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-form',{attrs:{"id":"components-form-demo-normal-login","form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['phone_number', {
          normalize: this.$lodash.trim,
          rules: [
            { required: true, message: '请输入手机号' },
            { pattern: /^1[3-9]\d{9}$/, message: '手机号格式不正确' } ],
          validateTrigger: 'blur'
        }]),expression:"['phone_number', {\n          normalize: this.$lodash.trim,\n          rules: [\n            { required: true, message: '请输入手机号' },\n            { pattern: /^1[3-9]\\d{9}$/, message: '手机号格式不正确' },\n          ],\n          validateTrigger: 'blur'\n        }]"}],attrs:{"placeholder":"请输入手机号"}})],1),_c('a-form-item',[_c('a-row',{attrs:{"gutter":8}},[_c('a-col',{attrs:{"span":17}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['sms_captcha', {
              normalize: this.$lodash.trim,
              rules: [{ required: true, message: '请输入验证码' }],
            }]),expression:"['sms_captcha', {\n              normalize: this.$lodash.trim,\n              rules: [{ required: true, message: '请输入验证码' }],\n            }]"}],attrs:{"placeholder":"请输入验证码"}})],1),_c('a-col',{attrs:{"span":7}},[(_vm.btnTitle)?_c('a-button',{staticClass:"register-form-captcha-button",attrs:{"type":"primary","block":true,"disabled":_vm.disabled},on:{"click":_vm.getSmsCaptcha}},[_vm._v(" "+_vm._s(_vm.btnTitle)+" ")]):_vm._e()],1)],1)],1),_c('a-form-item',[_c('a-button',{staticClass:"register-form-button",attrs:{"loading":_vm.submitting,"type":"primary","html-type":"submit"}},[_vm._v(" 下一步 ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }